import React, {useEffect, useState} from "react";
import {View, StyleSheet, Linking, Platform} from "react-native";
import { RadioOption } from "react/legacy/parkable-components/radio/Radio";
import Radio from "react/legacy/parkable-components/radio/Radio";
import Text from "react/legacy/parkable-components/text/Text";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Strings from '../../util/localization/localization';
import { UpdateUserOptionsRequest } from "../../dto/UpdateUserOptionsRequest";
import { updateUserOptions, useUserOptions } from "../../api/userOptions";
import { createRoute } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import {useAlertSnackbar} from "../../root/alert-snackbar/alert-snackbar";
import { startActivityAsync, ActivityAction } from 'expo-intent-launcher';
import * as Application from 'expo-application';
import ParkableBaseView from "../../components/common/ParkableBaseView";
import {Select} from "react/legacy/parkable-components";
import {useUserRoles} from "react/api/user/user.api";
import {Role} from "react/model/User";
import { PickerSelectProps } from "react-native-picker-select";

const DONT_CANCEL_IN_ADVANCE = "DONT_CANCEL_IN_ADVANCE";

const NotificationSettingsView = () => {
    const { userOptions, isLoading, mutate } = useUserOptions();
    const {showSnackBar} = useAlertSnackbar();
    const [loading, setLoading] = useState<boolean>(false);
    const _loading = isLoading || loading;
    const { userRoles } = useUserRoles();
    const isPrivateMember =  (userRoles??[])
        .filter(ur => ur.role.name !== Role.PUBLIC)
        .some(ur => !ur.suspended);
    const onChangeReminder = async (option: RadioOption) => {
        const sessionTimeoutHours = parseInt(option.value);
        try {
            setLoading(true);
            const updateRequest: UpdateUserOptionsRequest = {};
            if (isNaN(sessionTimeoutHours)) {
                updateRequest["sessionTimeoutsEnabled"] = false;
            } else {
                updateRequest["sessionTimeoutsEnabled"] = true;
                updateRequest["sessionTimeoutSeconds"] = sessionTimeoutHours * 60 * 60;
            }
            await updateUserOptions(updateRequest);
        } catch (e) {
            showSnackBar({
                text: Strings("error"),
                hideDismiss: true,
                style: {
                    backgroundColor: Colours.RED,
                },
            });
        } finally {
            await mutate();
            setLoading(false);
        }
    }

    const selected = `${userOptions?.sessionTimeoutsEnabled && userOptions.sessionTimeoutSeconds
      ? userOptions.sessionTimeoutSeconds / 3600
      : Strings("auto_end_after_1_week")}`;

    const [timeLimitToStayInWaitList, setTimeLimitToStayInWaitList] = useState<string|null|undefined>();

    useEffect(() => {
        if(userOptions && timeLimitToStayInWaitList === undefined){
            setTimeLimitToStayInWaitList(userOptions.timeLimitToStayInWaitList);
        }
    }, [userOptions]);

    const onChangeTimeLimitToStayInWaitList = async (value: string|null) => {
        if(value === timeLimitToStayInWaitList){
            return;
        }

        try {
            setLoading(true);
            setTimeLimitToStayInWaitList(value);
            const updateRequest: UpdateUserOptionsRequest = {
                timeLimitToStayInWaitList: value
            };
            await updateUserOptions(updateRequest);
        } catch (e) {
            showSnackBar({
                text: Strings("error"),
                hideDismiss: true,
                style: {
                    backgroundColor: Colours.RED,
                },
            });
        } finally {
            await mutate();
            setLoading(false);
        }
    }

    const changeEmailPreference = () => {
        (async () => {
            const URL = "https://parkable.us10.list-manage.com/unsubscribe?u=9a99971c3598b22f70e992b85&id=fe97c6a88a";
            if (await Linking.canOpenURL(URL)) {
                Linking.openURL(URL);
            }
        })();
    }

    const changeNotificationSettings = () => {
        if (Platform.OS === "ios") {
            Linking.openURL("app-settings:").catch(console.error);
        } else if (Platform.OS === "android") {
            const params = { extra: { "android.provider.extra.APP_PACKAGE": Application.applicationId } };
            startActivityAsync(ActivityAction.APP_NOTIFICATION_SETTINGS, params).catch(console.error);
        }
    };

    const options: RadioOption[] = [
        {label: Strings("remind_me_every_")(6), value: '6'},
        {label: Strings("remind_me_every_")(12), value: '12'},
        {label: Strings("remind_me_every_")(24), value: '24'},
        {label: Strings("auto_end_after_1_week"), value: Strings("auto_end_after_1_week")},
    ];

    const timeOptions = [
        { label: Strings("six_pm_night_before"), value: '18:00' },
        { label: Strings("seven_pm_night_before"), value: '19:00' },
        { label: Strings("eight_pm_night_before"), value: '20:00' },
        { label: Strings("nine_pm_night_before"), value: '21:00' },
        { label: Strings("ten_pm_night_before"), value: '22:00' },
        { label: Strings("eleven_pm_night_before"), value: '23:00' },
        { label: Strings("midnight_capital"), value: '00:00' },
    ];

    const pickerStyle = {
        inputIOS: styles.pickerText,
        inputAndroid: styles.pickerText,
        inputWeb: styles.pickerText,
        viewContainer: styles.pickerViewContainer,
    }

    const placeholderDoNotCancel: PickerSelectProps['placeholder'] = {
        label: Strings("dont_cancel_in_advance"),
        value: DONT_CANCEL_IN_ADVANCE,
    };

    const showNotificationsSection = () => {
        if(Platform.OS === "web") {
            return null;
        }
        else {
            return <View style={styles.section}>
                <Text bold>
                    {Strings("push_notifications")}
                </Text>
                <Text style={styles.sectionText}>
                    {Strings("if_you_would_like_to_turn_push_notifications_from_the_Parkable_app_on_or_off")}
                    <Text style={styles.linkText} onPress={changeNotificationSettings}>
                        {Strings("change_your_system_notification_settings")}
                    </Text>
                </Text>
            </View>
        }
    }

    return (
        <ParkableBaseView loading={_loading}>
            <Text h1 bold style={styles.title}>
                {Strings("notifications_preferences")}
            </Text>
            <Text grey>
                {Strings("manage_your_app_and_email_notifications_and_preferences")}
            </Text>

            <View style={styles.section}>
                <Text bold>
                    {Strings("marketing_emails")}
                </Text>
                <Text style={styles.sectionText}>
                    {Strings("if_you_would_like_to_unsubscribe_from_Parkable_marketing_emails")}
                    <Text style={styles.linkText} onPress={changeEmailPreference}>
                        {Strings("please_update_your_email_preferences")}
                    </Text>
                </Text>
            </View>
            {showNotificationsSection()}
            <View style={styles.lineBreak} />
	        <View style={styles.section}>
		        <Text bold>
			        {Strings("waitlisted_requests")}
		        </Text>
		        <Text style={styles.sectionText}>
			        {Strings("dont_want_a_request_on_the_waitlist_to_convert_into_a_booking_at_the_last_moment_q")}
		        </Text>
		        <Text style={styles.sectionText}>
			        {Strings("set_a_time_to_cancel_your_waitlisted_requests")}
		        </Text>
	        </View>

            {userOptions && userRoles && isPrivateMember && <View style={styles.section}>
                <Text h4 bold>{Strings("time")}</Text>
                <Select
                    error={""}
                    items={timeOptions}
                    value={timeLimitToStayInWaitList}
                    placeholder={placeholderDoNotCancel}
                    onValueChange={onChangeTimeLimitToStayInWaitList}
                    pickerStyle={pickerStyle}
                    style={{height: 45}}/>
            </View>}

            <View style={styles.section}>
                <Text bold>{Strings("session_auto_end")}</Text>
                <Text style={styles.sectionText}>
                    {Strings("session_reminder") + ' ' + Strings("session_reminder_desc")}
                </Text>
                <View style={styles.radioButtonSection}>
                    <Radio
                        options={options}
                        onChange={onChangeReminder}
                        value={selected}
                    />
                </View>
            </View>
            <View style={styles.section}>
                <View style={styles.messageContainer}>
                    <Text>{Strings("autoend_preference_message")}</Text>
                </View>
            </View>
        </ParkableBaseView>
    );
}

const styles = StyleSheet.create({
    mainContainer: {
        width: "100%",
        height: "100%",
        backgroundColor: Colours.NEUTRALS_WHITE,
    },
    title: {
        paddingTop: 18,
    },
    scrollContainer: {
        marginBottom: 45,
    },
    section: {
        paddingTop: 18,
        paddingBottom: 9,
    },
    sectionText:{
        color: Colours.GREY_70,
    },
	lineBreak: {
		height: 1,
		width: "100%",
		backgroundColor: Colours.GREY_BORDER,
        marginTop: 18,
        marginBottom: 9,
	},
    linkText: {
        color: Colours.GREY_70,
        textDecorationLine: "underline",
    },
    radioButtonSection: {
        paddingTop: 9,
    },
    pickerText: {
        backgroundColor: Colours.TRANSPARENT,
        borderColor: Colours.TRANSPARENT,
    },
    pickerViewContainer: {
        flex: 1,
        justifyContent: "center",
    },
    messageContainer: {
        width: "100%",
        height: "auto",
        padding: 15,
        borderRadius: 6,
        backgroundColor: Colours.ORANGE_100,
    }
});

export default NotificationSettingsView;

export const NotificationSettingsRoute = createRoute({
    path: Routes.NotificationSettingsView,
});
