import {useMySWR} from "../http/use-swr";
import {Nully} from "../../util/nully";
import { Activity } from "../../model/Types";
import EntityResponse from "../../dto/EntityResponse";
import { generateQueryString } from "../http/rest";

export const useCalculateProcessingFee = (amount: Nully<number>,
                                          territoryId: Nully<number>,
                                          activity: Nully<Activity>,
                                          parkId: Nully<number>,
                                          bayId: Nully<number>) => {
    const params = generateQueryString({ amount, activity })
    return useMySWR<EntityResponse<number>>(
        territoryId && amount && activity && parkId && bayId ? `processing-fee/calculate/${territoryId}/${parkId}/${bayId}${params}` : null);
};
