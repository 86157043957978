import React, { useCallback, useEffect, useState } from "react";
import Text from "react/legacy/parkable-components/text/Text";
import Colours from "react/legacy/parkable-components/styles/Colours";
import Icon from "react/legacy/parkable-components/icon/Icon";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import Strings from "../../../util/localization/localization";
import { getTimeElapsedText } from "./evSessionElapsedFunction";
import { OcpiSession, SessionStatus } from "react/model/ocpi/OcpiSession";
import { Territory } from "react/model/Territory";
import { orderBy } from "lodash";

type Props = {
    sessions?: Array<OcpiSession | null>;
    territory?: Territory;
    showConnectNozzle: Function;
    style: {};
};

export default function EVCarouselItem(props: Props) {
    const { sessions, territory, showConnectNozzle, style } = props;
    const [chargeTime, setChargeTime] = useState("");
    const [kwh, setKwh] = useState("");
    const [chargeColour, setChargeColour] = useState(Colours.NEUTRALS_WHITE);

    const getKwhDetails = (sessions: OcpiSession[]) => {
        const currentSession = orderBy(
            (sessions || []).filter(
                (s) => s !== null && s.status !== SessionStatus.INVALID && s.status !== SessionStatus.PENDING
            ),
            "last_updated",
            "desc"
        )[0];
        const currentSessionKwh = currentSession.kwh || 0;
        setKwh(currentSessionKwh.toFixed(2) + `${Strings("kwh_added")}`);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (!sessions || sessions.length === 0) {
                setChargeTime(Strings("charger_idle"));
                setKwh(Strings("click_to_start"));
                setChargeColour(chargeColour === Colours.RED ? Colours.GREY_10 : Colours.RED)
            }
            else {
                const filteredSessions: OcpiSession[] = sessions.filter(s => s !== null) as OcpiSession[];
                const currentlyCharging = filteredSessions.some((s) => s.status === SessionStatus.ACTIVE);
                const invalidCharging = filteredSessions.some((s) => s.status === SessionStatus.INVALID);
                const pendingCharging = filteredSessions.some((s) => s.status === SessionStatus.PENDING);
                if (currentlyCharging) {
                    setChargeTime(!!territory ? getTimeElapsedText(filteredSessions) : "");
                    getKwhDetails(filteredSessions);
                    setChargeColour(chargeColour === Colours.GREEN_300 ? Colours.GREY_10 : Colours.GREEN_300);
                } else if (pendingCharging) {
                    setChargeTime(Strings("pending_charge"));
                    setKwh(Strings("waiting_for_charger"));
                    setChargeColour(chargeColour === Colours.RED ? Colours.GREY_10 : Colours.RED);
                } else if (invalidCharging) {
                    setChargeTime(Strings("charger_idle"));
                    setKwh(Strings("not_charging"));
                    setChargeColour(chargeColour === Colours.RED ? Colours.GREY_10 : Colours.RED);
                } else {
                    setChargeTime(Strings("charger_idle"));
                    getKwhDetails(filteredSessions);
                    setChargeColour(Colours.GREY_10)
                }
            }
        }, 500);
        return () => clearInterval(interval);
    }, [sessions]);

    const onPress = useCallback(() => {
        const currentlyCharging = !!sessions && sessions.filter(s => s !== null && s.status === SessionStatus.ACTIVE).length > 0;
        if (!sessions || !currentlyCharging) {
            showConnectNozzle();
        }
    }, [sessions]);

    return (

        <TouchableOpacity style={[styles.mainStyle, style]} onPress={onPress}>
            <Icon color={chargeColour} style={styles.icon} iconStyle={{ fontSize: 81 }} name={"batterycharging"} />
            <View style={styles.subView}>
                <Text grey small>{chargeTime}</Text>
                <Text bold>{kwh}</Text>
            </View>
        </TouchableOpacity>);
}

const styles = StyleSheet.create({
    mainStyle: {
        backgroundColor: Colours.NEUTRALS_WHITE,
        paddingTop: 18,
        paddingLeft: 14,
        paddingRight: 14,
        paddingBottom: 18,
        alignItems: "center",
        justifyContent: "space-between",
        flex: 1,
        borderWidth: 1,
        borderColor: Colours.GREY_10
    },
    icon: {
        width: "100%",
        flex: 2,
        backgroundColor: Colours.GREY_10
    },
    subView: {
        alignItems: "center",
    }
})
