import React, { useCallback, useEffect, useMemo, useState } from "react";
import {Image, StatusBar, StyleSheet, View} from "react-native";
import MapFooterView from "../../components/map/MapFooterView";
import NotificationView from "../../components/map/notifications/NotificationView";
import MenuButton from "../../components/map/parkableMapView/MenuButton";
import GPSLocationDialogView from "../../components/map/parkableMapView/GPSLocationDialogView";
import ModalMessageCard, { ModalType } from "../../components/common/ModalMessageCard";
import QRScanFab from "../../components/map/QRScanFab";
import { logEvent } from "react/util/analytics";
import { CampusDTO } from "../../api/campus/dto/CampusDTO";
import { NavigationProps } from "react/navigation/constants";
import { Coords } from "google-map-react";
import { CampusPinWeb } from "../../components/map/parkableMapView/web/google/campus/campus-pin.web";
import * as Location from "expo-location";
import { getClosestCampus, useClosestParks } from "../../components/map/parkableMapView/web/google/map.constants";
import { Routes } from "react/navigation/root/root.paths";
import { GoogleMap } from "../../components/map/parkableMapView/web/google/google.web";
import { handleMessageModal } from "../../redux/actions/user";
import { IRootReducer } from "../../redux/reducers/main";
import { useAppDispatch, useSelector } from "../../redux/redux";
import { Token } from "../../api/rest";
import FavouriteLinks from "../../components/map/parkableMapView/FavouriteLinks";
import { ParkPinWeb } from "../../components/map/parkableMapView/web/google/park/park-pin.web";
import { useMyPrivateParks } from "../../api/park";
import Strings from "react/util/localization/localization";
import { IconName } from "react/legacy/parkable-components/icon/Icons";
import { IconProps} from "react/legacy/parkable-components/icon/Icon";
import { TextProps } from "react/legacy/parkable-components/text/Text";
import Colours from "react/legacy/parkable-components/styles/Colours";
import MapSettingsFab from "../../components/map/MapSettingsFab";
import searchMarkerImage from "react/resources/redMapPin.png";

const DEFAULT_ZOOM = 16;
const DEFAULT_COORDS: Coords = {
    lat: -36.84486,
    lng: 174.76761,
};

type Props = NavigationProps<Routes.ParkableMapView>

export default function ParkableMapWebView(props: Props) {
    const dispatch = useAppDispatch();
    const user = useSelector((state: IRootReducer) => state.user.user)
    const messageModalPayload = useSelector((state: IRootReducer) => state.user.messageModalPayload)
    const {api} = useSelector((state: IRootReducer) => state.data)
    const token = useSelector(state => ({
        firebaseToken: state.auth.fireBaseToken
    } as Token));

    const [bounds, setBounds] = useState<{ ne: Coords, sw: Coords } | undefined>()

    const {navigation, route} = props;
    const { params } = route;

    const zoom = params?.zoom ?? DEFAULT_ZOOM;

    const { parks: myParks, isLoading: parkLoading } = useMyPrivateParks();
    const park = myParks?.length ? myParks[0] : undefined;
    const parkLocation = useMemo(() => park ? { lat: park.latitude, lng: park.longitude } : undefined, [park]);

    const queryLoc = useMemo(() => params?.latitude && params?.longitude
        ? {
            lat: params?.latitude,
            lng: params?.longitude
        } : undefined, [params?.latitude, params?.longitude]);

    const mapCoords = parkLoading ? undefined : queryLoc ?? parkLocation ?? DEFAULT_COORDS;

    const [gpsCoords, setGpsCoords] = useState<Coords|null>(null);

    const boundPoint = bounds?.ne;

    const [parks] = useClosestParks({
        boundPoint,
        centerPoint: mapCoords,
    })

    const [campuses, setCampuses] = useState<CampusDTO[] | undefined>([]);

    useEffect(() => {
        if (mapCoords && bounds?.ne) {
            getClosestCampus({
                api,
                token,
                boundPoint,
                centerPoint: mapCoords
            }).then(setCampuses);
        }
    }, [mapCoords, boundPoint]);

    const [showMessage, setShowMessage] = useState(false);
    const [modalProps, setModalProps] = useState({
        iconName: "checkboxtick" as IconName,
        iconProps: {white:true} as Omit<IconProps, 'name'>,
        label: null as string | null,
        labelProps: undefined as TextProps | undefined,
        title: null as string | null,
        titleProps: undefined as TextProps | undefined,
        signage: null as string | null,
        modalType: null as ModalType | null,
        payloadData: 0 as number
    });

    const onModalHide = useCallback(() => {
        dispatch(handleMessageModal(false, null, null)); //clean up redux handler
        setShowMessage(false);
    }, []);

    useEffect(() => {
        let cleanup = false;
        let subscription: Location.LocationSubscription;
        subscribeToLocation()
            .then(s => {
                if (!s) {
                    return;
                } else if (cleanup) {
                    s.remove();
                } else {
                    subscription = s;
                }
            })
            .catch(console.error);
        return () => {
            if (subscription) {
                subscription.remove();
            } else {
                cleanup = true;
            }
        };
    }, []);

    useEffect(() => {
        if(messageModalPayload?.status) {
            (async () => {
                const {data, modalType} = messageModalPayload;
                if (!data || !modalType) {
                    return;
                }
                const iconName = 'star';
                const label = Strings("private_parking");
                const labelProps = {style: {color: Colours.PINK}};
                setModalProps({...modalProps, iconName, label, labelProps, modalType, payloadData: data});
                setShowMessage(true)
            })()
        }
    }, [messageModalPayload]);

    async function subscribeToLocation() {
        const permission = await getLocationPermission();
        if (!permission.granted) {
            console.warn("No location permission");
            return;
        }
        return await Location.watchPositionAsync({}, location => {
            const { latitude: lat, longitude: lng } = location.coords;
            setGpsCoords({ lat, lng });
        });
    }

    async function getLocationPermission() {
        const permission = await Location.getForegroundPermissionsAsync();
        if (permission.granted || permission.canAskAgain) {
            return permission;
        }
        return await Location.requestForegroundPermissionsAsync();
    }

    const onSearchViewPressed = useCallback(() => {
        const params = { userId: `${user?.id ?? 0}` };
        logEvent(undefined, "map_search", params);
    }, []);

    const handleMapChange = (args: {
        coords: Coords,
        zoom: number,
        ne: Coords,
        sw: Coords
    }) => {
        navigation.setParams({
            latitude: args.coords.lat,
            longitude: args.coords.lng,
            zoom: args.zoom
        });
        setBounds({ ne: args.ne, sw: args.sw });
    }
    const navigationParams = props.route.params ?? {};
    let searchPoint:Coords|undefined= undefined;

    if (!!navigationParams?.latitude && !!navigationParams?.longitude && !!navigationParams?.bySearch) {
        searchPoint = {
            lat: navigationParams.latitude,
            lng: navigationParams.longitude
        };
    }

    return (
        <View style={styles.main}>
            <StatusBar backgroundColor={"transparent"} translucent={true} barStyle={"dark-content"} />
            <View style={styles.mapContainer}>
                <GoogleMap
                    center={mapCoords}
                    options={{
                        disableDefaultUI: true,
                        zoomControl: true,
                        zoomControlOptions: { position: 6 },
                    }}
                    zoom={zoom ?? DEFAULT_ZOOM}
                    onChange={(e) =>
                        handleMapChange({
                            coords: e.center,
                            zoom: e.zoom,
                            ne: e.bounds.ne,
                            sw: e.bounds.sw,
                        })
                    }
                >
                    {gpsCoords && (
                        <View {...gpsCoords} style={styles.dot1}>
                            <View style={styles.dot2} />
                        </View>
                    )}

                    {campuses?.map((campus) => {
                        const coords = { lat: campus.latitude, lng: campus.longitude };
                        return <CampusPinWeb key={campus.id} {...coords} info={campus} parks={parks} />;
                    })}
                    {Object.values(parks)
                        .filter(park => !park.hasCampus)
                        .map((park) => {
                            // Each park can have ev, long term and casual
                            const coords = { lat: park.latitude, lng: park.longitude };
                            return <ParkPinWeb key={park.parkId} {...coords} info={park} />;
                        })}
                    {!!searchPoint && (
                        <View {...searchPoint} style={styles.redPin}>
                            {/* @ts-ignore */}
                            <Image style={styles.redPin} source={searchMarkerImage} />
                        </View>
                    )}
                </GoogleMap>

                <NotificationView />
                <QRScanFab />
                <MapSettingsFab />
                <ModalMessageCard showMessage={showMessage} {...modalProps} onModalHide={onModalHide} />
            </View>
            <MenuButton />
            <MapFooterView showSearchView onSearchViewPressed={onSearchViewPressed} />
            {/* BAC-7431 Remove favourites component from DOM and re-render it when the map view receives focus.
             This is a work around for a bug where the bottom drawer opens when you navigate away from (and then back to) the map view.
             Also prevent animate each time it remounts. */}
            {navigation.isFocused() && <FavouriteLinks animateOnMount={false} />}
            <GPSLocationDialogView />
        </View>
    );
}

const styles = StyleSheet.create({
    dot1: {
        borderRadius: 50,
        width: 25,
        height: 25,
        backgroundColor: "#5384ED33",
        padding: 6,
    },
    dot2: {
        borderWidth: 2,
        borderRadius: 50,
        borderColor: "#FFFFFF",
        width: 13,
        height: 13,
        backgroundColor: "#5384ED",
    },
    main: {
        width: "100%",
        height: "100%",
    },
    mapContainer: {
        flex: 1,
        position: "relative",
    },
    darkenMap: {
        backgroundColor: "black",
        opacity: 0.2,
        position: "absolute",
        width: "100%",
        height: "100%",
    },
    bayNumberMessage: {
        fontSize: 100,
        lineHeight: 100,
        marginBottom: 18,
        fontWeight: "900",
    },
    redPin: {
        height: 41,
        width: 28,
    }
});
