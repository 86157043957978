/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Bruno on 24/04/19.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import {get, post, put} from "./rest";
import {Park} from "../model/Park";
import {PartialPark} from "../model/PartialPark";
import {ParkInfo2} from "../model/ParkInfo2";
import {Token} from "./rest";

export interface PartialParkResponse {
    park: Park | null,
    parkInfo: ParkInfo2 | null,
    partialPark: PartialPark | null,
}

export const createOrGetUnpublishedPartialParkAPI = (api:string, token:Token) =>
    post(token, `${api}v2/partialpark`) as Promise<PartialParkResponse>;

export const getUnpublishedPartialParkAPI = (api:string, token:Token) =>
    get(token, `${api}v2/partialpark`) as Promise<PartialParkResponse>;

export const publishPark = (api:string, token:Token, partialParkId:number) =>
    put(token, `${api}v2/partialpark/publishPark/${partialParkId}`) as Promise<PartialParkResponse>;

export const updatePartialParkAPI = (api:string, token:Token, partialParkId:number, data:Object) =>
    put(token, `${api}v2/partialpark/${partialParkId}`, data) as Promise<PartialParkResponse>;

export const discardPartialParkAPI = (api:string, token:Token, partialParkId:number) =>
    put(token, `${api}v2/partialpark/${partialParkId}/discard`) as Promise<PartialParkResponse>;


